<template>
    <div id="JoinForm" class="serif">
        <ElForm
            label-position="top"
            label-width="240px"
            ref="joinFormRef"
            :model="joinFormData"
            :rules="joinFormRules"
        >
            <p class="f14 f12-mb w80-mb">
                「大自然界」へのお問い合わせをお受けしております。<br />
                カウンセリング・勉強会に関するご質問やご相談も、こちらからお気軽にご連絡下さい。<br />
                必要事項をご入力の上、下記のフォームよりご送信下さい。<br />
                <br />
                お問い合わせの内容によっては、回答にお時間をいただく場合がございます。あらかじめご了承下さい。<br />
                いただいたメールアドレスに誤りがある場合や、システム障害などにより、お返事出来ない場合がございます。<br />
                <br />
                なお、基本セミナーへの参加をご希望の場合は、「<router-link
                    class="bold underline"
                    :to="{ name: 'Seminar' }"
                    >基本セミナー申し込みフォーム</router-link
                >」よりお申し込み下さい。
            </p>
            <h3 class="f14 f12-mb">必要事項をご記入ください。</h3>
            <section id="join-form-basic-info">
                <ElFormItem class="f14 f14-mb" label="お名前" prop="name">
                    <ElInput v-model="joinFormData.name"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス"
                    prop="email"
                >
                    <ElInput v-model="joinFormData.email"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス（確認用）"
                    prop="emailCheck"
                >
                    <ElInput v-model="joinFormData.emailCheck"></ElInput>
                </ElFormItem>
            </section>
            <h3 class="f14 f12-mb">
                下記のいずれか1つにチェックを入れてください。<span
                    class="is-required"
                    >※必須</span
                >
            </h3>
            <section id="join-form-options" class="w50 w80-mb">
                <ElFormItem class="f14 f14-mb" prop="option">
                    <ElRadioGroup v-model="joinFormData.option">
                        <ElRadio label="勉強会の申し込み"></ElRadio>
                        <ElRadio label="書籍の購入"
                            >書籍の購入&nbsp;商品の詳細は
                            <router-link
                                class="bold underline"
                                :to="{ name: 'Book' }"
                                >こちら</router-link
                            >
                            <p class="f12 f12-mb" style="white-space: normal">
                                ※
                                ご購入される方は、下の「お問い合わせ内容詳細」の入力欄に、ご希望の書籍タイトル、数量、送り先氏名、住所をご記入ください。
                            </p></ElRadio
                        >
                        <ElRadio label="その他"></ElRadio>
                    </ElRadioGroup>
                </ElFormItem>
            </section>
            <h3 class="f14 f12-mb">
                お問い合わせ内容をご自由にご記入ください。
            </h3>
            <section id="join-form-question" class="w70 w80-mb">
                <ElFormItem
                    class="f14 f14-mb"
                    prop="question"
                    label="お問い合わせ内容 詳細"
                >
                    <ElInput
                        type="textarea"
                        resize="none"
                        v-model="joinFormData.question"
                    ></ElInput>
                </ElFormItem>
            </section>
            <section id="join-form-submit">
                <button
                    class="f29 f22-mb"
                    @click.prevent="submitForm(joinFormRef)"
                >
                    送 信
                </button>
            </section>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'JoinForm',
}
</script>
<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { postJoin } from '@/service/api'
import { getJoinFormRules } from '@/utils/formRules'

const joinFormRef = ref(null)
const joinFormData = reactive({
    name: '',
    email: '',
    emailCheck: '',
    option: '',
    question: '',
})
const joinFormRules = getJoinFormRules(joinFormData)
const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            let formData = {
                name: joinFormData.name,
                email: joinFormData.email,
                option: joinFormData.option,
                question: joinFormData.question,
            }
            postJoin(formData).then(() => {
                ElMessage({
                    message: 'ご記入いただいた情報は無事送信されました。',
                    type: 'success',
                    offset: 100,
                })

                setTimeout(() => {
                    joinFormData.name = ''
                    joinFormData.email = ''
                    joinFormData.emailCheck = ''
                    joinFormData.option = ''
                    joinFormData.question = ''
                    location.reload()
                }, 2000)
            })
        } else {
            return false
        }
    })
}
</script>

<style lang="scss" scoped>
@import '@/style/element.scss';

#JoinForm {
    color: $gray6;
    text-align: center;
    h2 {
        margin-bottom: 1em;
    }
    h3 {
        text-align: center;
        background: rgba($majorPurple, 0.1);
        .is-required {
            color: red;
        }
    }
    button {
        @include solidButtonStyle();
        margin: 0 auto;
        width: 200px;
    }
    .margin {
        margin-bottom: 2em;
    }

    /deep/ .el-radio {
        margin: 3px 0;
        .el-radio__input {
            align-self: start;
            margin-top: 7px;
        }
    }
    /deep/ .el-textarea__inner {
        height: 16em;
    }
    @media screen and (min-width: 768px) {
        h3 {
            margin: 5em 0 2em;
        }
        #join-form-submit {
            margin: 50px 0 80px;
        }
        section {
            padding: 0 Max(calc(50% - 240px), 60px);
        }
        #join-form-basic-info {
            padding: 0 Max(calc(50% - 180px), 60px);
        }
    }
    @media screen and(max-width:767px) {
        padding: 40px 0;
        h3 {
            margin: 3em 0 2em;
            line-height: 4em;
        }
        #join-form-submit {
            margin-top: 40px;
        }
    }
}
</style>
